<template>
    <!-- 考勤管理 > 缺卡设置 -->
    <missing-card-settings-scroll-wrapper></missing-card-settings-scroll-wrapper>
</template>

<script>
import MissingCardSettingsScrollWrapper from '@/components/scrollWrapper/MissingCardSettings.vue';

export default {
    name: "missingCardSettings",
    components: {
        MissingCardSettingsScrollWrapper
    }
}
</script>
