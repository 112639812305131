<template>
  <!-- 工作台 -->
  <KeepAliveRouterView />
</template>

<script>
export default {
  name: "workbench"
}
</script>

<style scoped>

</style>
