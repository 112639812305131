<template>
    <div>
        <systemHelpScrollWrapper></systemHelpScrollWrapper>
    </div>
</template>

<script>
    import systemHelpScrollWrapper from '@/components/scrollWrapper/systemHelp'
    export default {
        name: "systemHelp",
        components: {
            systemHelpScrollWrapper,
        },
    }
</script>

<style lang="scss" scoped>

</style>