<template>
    <!-- 待办 -->
    <div>
        <dealt-list-scroll-wrapper />
    </div>
</template>

<script>
import dealtListScrollWrapper from "@/components/scrollWrapper/dealtList";
export default {
    name: "dealtList.vue",
    components: {
        dealtListScrollWrapper
    }
}
</script>

<style scoped>

</style>
