<template>
  <div class="center-main">
      <div class="content-main">
          <div class="form-ref">
            <div class="disFlex">
              <el-form ref="formRef" inline :model="form" class="header__form__ipt">
              <el-form-item label="" prop="systemMark">
                <el-select v-model="form.systemMark" placeholder="系统标识" clearable  class="input-width" style="width: 170px;">
                    <el-option
                        v-for="item in systemMarkOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="projectId" v-if="showId">
                <el-input v-model="form.projectId" placeholder="项目id" clearable class="input-width" style="width: 170px;"/>
              </el-form-item>
              <el-form-item label="" prop="operator">
                <el-input v-model="form.operator" placeholder="操作人" clearable class="input-width" style="width: 170px;"/>
              </el-form-item>
              <el-form-item label="" prop="operate">
                <el-input v-model="form.operate" placeholder="操作名" clearable class="input-width" style="width: 170px;"/>
              </el-form-item>
              <el-form-item label="" prop="operateTime">
                <el-date-picker @change="getList(0)" v-model="operateTime" type="daterange" range-separator="至" start-placeholder="操作开始日期"
                  end-placeholder="操作结束日期" value-format="yyyy-MM-dd" class="date-picker-width" style="width: 350px;"/>
              </el-form-item>
              <!--        <el-form-item label="开始日期" prop="operatingStartTime">-->
              <!--          <el-date-picker v-model="form.operatingStartTime" type="date" placeholder="开始日期" value-format="yyyy-MM-dd" />-->
              <!--        </el-form-item>-->
              <!--        <el-form-item label="结束日期" prop="operatingEndTime">-->
              <!--          <el-date-picker v-model="form.operatingEndTime" type="date" placeholder="结束日期" value-format="yyyy-MM-dd" />-->
              <!--        </el-form-item>-->
              </el-form>
              <el-button type="primary" size="mini" @click="getList(0)">查询</el-button>
              <el-button type="enquiry" @click="resetForm">重置</el-button>
              <div v-if="check(['log:export'])" class="button-line" style="margin-left: 20px;"></div>
              <el-button v-if="check(['log:export'])" type="enquiry" plain @click="exportLog(0)">导出</el-button>
            </div>
          </div>

          <!-- <el-table id="table" ref="table" :data="list" header-align="center" align="center" :height="height || null" :header-cell-style="{background:backgroundHeader}"> -->
            <!-- <el-table-column label="序号" width="60px" align="center">
              <template #default="scope">{{ scope.$index + 1 }}</template>
            </el-table-column> -->
            <!-- <el-table-column prop="id" label="主键"> </el-table-column> -->
            <!-- <el-table-column prop="systemMark" label="系统标识"> </el-table-column> -->
            <!-- <el-table-column prop="operatingTime" label="操作时间" align="left"> </el-table-column>
            <el-table-column prop="operator" label="操作人" align="left"> </el-table-column>
            <el-table-column prop="operateModule" label="操作模块" :show-overflow-tooltip="true" align="left"> </el-table-column>
            <el-table-column prop="operate" label="操作名" align="left"> </el-table-column>
            <el-table-column prop="content" label="内容" align="left" width="180px" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="roleName" label="角色" align="left"> </el-table-column>
            <el-table-column prop="ipAddress" label="IP地址" width="200px" align="left"> </el-table-column>
            <el-table-column prop="operatePlatform" label="操作平台" align="left"> </el-table-column>
          </el-table> -->
          <table-data
            v-loading="loading"
            id="table"
            ref="table"
            :config="tableConfig"
            :tableData="list"
          ></table-data>

          <Pagination :total="total" :page.sync="form.pageNum" :limit.sync="form.pageRow" @pagination="getList" />
      </div>
  </div>
</template>

<script>
  import {
    logList,
    logListExport,
    check
  } from '../SystemOperateLog.vue'
  import { Pagination } from 'common-local';
  import TableData from "@/components/scrollWrapper/Sub/TableData"
  import onResize from "@/mixins/onResize"
  import { mapState } from 'vuex';
  export default {
    mixins: [onResize],
    props: {
      token: String,
      projectId: String,
      systemMark: String
    },
    components: {
      TableData,
      Pagination,
    },
    data() {
      return {
        loading: false,
        backgroundHeader: '#FAFBFC',
        form: {
          pageNum: 1,
          pageRow: 20,
          systemMark: 'xk_cloud_campus', //系统标识
          projectId: '', //项目id
          operator: '', //操作人
          operate: '', //操作类别
          operatingStartTime: '', // 操作开始日期-yyyy-mm-dd
          operatingEndTime: '',
          isJump: 1 // 是否为接入系统跳转，1是，2否
        },
        operateTime: [],
        tableConfig: {
          thead: [
            {
              label: '操作时间',
              prop: 'operatingTime',
              align: 'center'
            },
            {
              label: '操作人',
              prop: 'operator',
              align: 'center'
            },
            {
              label: '操作模块',
              prop: 'operateModule',
              align: 'center'
            },
            {
              label: '操作名',
              prop: 'operate',
              align: 'center'
            },
            {
              label: '内容',
              prop: 'content',
              type: 'tooltipColumn',
              align: 'center',
              splitSign: '，',
              className: 'text-spacing-reduction'
            },
            {
              label: '角色',
              prop: 'roleName',
              type: 'tooltipColumn',
              align: 'center',
              splitSign: ',',
              className: 'text-spacing-reduction'
            },
            {
              label: 'IP地址',
              prop: 'ipAddress',
              align: 'center'
            },
            {
              label: '操作平台',
              prop: 'operatePlatform',
              align: 'center'
            },
          ],
          check: false,
          height: ""
        },
        list: [],
        total: 0,
        showId: true,
        height: '',
        systemMarkOptions: [
            {
                label: '智慧校园',
                value: 'xk_cloud_campus'
            },
            {
                label: '智慧星',
                value: 'smart_star'
            }
        ]
      }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        })
    },
    created() {
      // if (this.$route.query.systemMark){
      //   this.form.systemMark = this.$route.query.systemMark
      //   this.form.projectId = this.$route.query.projectId
      //   this.showId = false
      // }
      this.form.projectId = this.schoolId;
      this.getList()
    },
    mounted() {},
    methods: {
      // 校验按钮权限
      check(v) {
        return check(v, this.$parent.userPermission)
      },
      getProp() {
        if (this.$parent.systemMark) {
          this.form.projectId = this.$parent.projectId
          this.showId = false
        }
      },
      // 获取数据列表
      getList(t = 1) {
        const token = this.token || this.$parent.token
        this.loading = true
        if (t === 0) {
          this.form.pageNum = 1
        }
        if (this.operateTime && this.operateTime.length > 0) {
          this.form.operatingStartTime = this.operateTime[0]
          this.form.operatingEndTime = this.operateTime[1]
        } else {
          this.form.operatingStartTime = ''
          this.form.operatingEndTime = ''
        }
        logList(this.form, token)
          .then((res) => {
            res.list.forEach(item => {
              item.operatingTime = item.operatingTime.substr(0, 16)
            })
            this.list = res.list
            this.total = res.totalCount
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      },
      // 删除
      exportLog(row) {
        if (!this.form.operatingEndTime) {
          return this.$message.warning('请选择日期')
        }
        this.loading = true
        logListExport(this.form, this.token).then((res) => {
          this.loading = false

          let temp = res.headers['content-disposition'].split(';')[1].split('filename=')[1]
          let iconv = require('iconv-lite')
          iconv.skipDecodeWarning = true // 忽略警告
          let fileName = iconv.decode(temp, 'gbk')

          const blob = res.data
          let url = window.URL.createObjectURL(blob)
          let aLink = document.createElement('a')
          aLink.href = url
          aLink.setAttribute('download', fileName)
          aLink.click()
          window.URL.revokeObjectURL(url) // 释放掉blob对象
          this.$message.success('导出成功!')
        }).catch(() => {
          this.loading = false
        })
      },
      //重置
      resetForm() {
        this.$refs.formRef.resetFields()
        this.operateTime = []
        this.form.operatingStartTime = ''
        this.form.operatingEndTime = ''
        // this.getList(0)
        this.form.pageNum = 1
        this.list = []
        this.total = 0
      }
    }
  }
</script>

<style scoped lang="scss">
  .center-main{
    padding: 0;
  }
  .form-ref {
    padding: 10px 10px 0;
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    .disFlex{
      display: flex;
      align-items: flex-start;
    }
  }
  .input-width {
    width: 159.98px;
  }
  .date-picker-width {
    width: 230px;
  }

  ::v-deep {
    .el-form-item--small.el-form-item {
        margin-bottom: 10px;
    }
    .el-input__inner {
        background: #F5F5F5;
        border: transparent 1px solid;
    }
    .el-range-editor .el-range-input {
        background: #F5F5F5;
    }
    .el-select .el-input .el-select__caret {
        color: #999999;
    }
    .el-input-group__append {
        color: #999999;
        background: #F5F5F5;
        border: none;
    }
    .el-input__inner:hover {
        border: #D4D6D9 1px solid;
    }
    .el-input__inner:focus {
        border: #3C7FFF 1px solid;
        background-color: #fff;
    }
    .el-input--suffix .el-input__inner{
        padding-right: 12px;
    }
	.el-form-item__content{
		height: 32px;
	}
}
</style>
