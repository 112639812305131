<template>
  <div>
    <el-main>
      <el-button @click="toChat('chat')">客服聊天</el-button>
      <el-button @click="toChat('')">客服管理</el-button>
    </el-main>
  </div>
</template>

<script>
  // import { getLoginToken } from '@/api/user.js'
  // const host = 'http://localhost:9528'

  export default {
    created() {
      this.toChat('chat')
    },
    methods: {
      toChat(path) {
        const name = this.$store.getters.name
        const host = process.env.VUE_APP_XK_SERVER_CHAT_BOT_URL
        const productId = 'xk_zhxy'
        this._fet('/school/login/getLogLoginTokenTemp', {username: name,productId:productId}).then(r => {
          window.open(
            `${host}/?projectId=${r.projectId}&token=${r.token}#/${path}?pid=${r.projectId}`,
            "_blank");
        })
      }
    }
  }
</script>

<style scoped>

</style>
