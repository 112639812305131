<template>
    <div></div>
</template>
<script>
import { CommonModel } from '@/models/Common.js'
import {removeToken, setToken, getToken} from "@/libs/auth";
import { param2Obj } from '@/libs/utils';
export default{
    name:"usbKeyJump",
    data(){
        return {
            // routingQuery:{
            //     "source":'',//来源
            //     "userName":'',//账户
            //     "password":'',//密码
            //     "projectMark": "",
            //     "addres": "",
            //     "plat": ""
            // }
        }
    },
    created(){
        this.init();
    },
    methods:{
        init(){
            this.initAssignment();
        },
        initAssignment(){
            removeToken()
            console.log(location.search,'location.search11111111111111111111111');
            const param = param2Obj(location.search);
            console.log(param,'param');
            if(param.source && param.userName && param.projectMark && param.addres && param.plat){
                const commonModel = new CommonModel();
                commonModel.autoLoginSchoolFromUsbKey(param).then((res)=>{
                    console.log(res,'接口返回的token');
                    if(res.data.code == '200'){
                        setToken(res.data.data.token)
                        setTimeout(()=>{
                            this.jumpPath(res.data.data.projectMark)
                        })
                    }else{
                        this.$message.error(res.data.msg)
                        this.$router.push('/login')
                    }
                })
            }else{
                this.$message.error('参数不全')
            }
            console.log(param,'跳转过来的url上携带的参数',);
        },
        jumpPath(projectMark){
            this.$router.push({
                path:'/firstPage',
                query:{
                    projectMark:projectMark
                }
            })
        }
    }
}
</script>
