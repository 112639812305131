<template>
    <!--首页-->
    <first-page></first-page>
</template>

<script>
import FirstPage from "@/components/scrollWrapper/FirstPage";
export default {
    components: {
        FirstPage
    }
}
</script>
